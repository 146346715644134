import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexSYNKnKeWmMMeta } from "/app/pages/boarding/index.vue?macro=true";
import { default as invitationa0U8Sz6KmFMeta } from "/app/pages/boarding/invitation.vue?macro=true";
import { default as new33EJdDPBaQMeta } from "/app/pages/boarding/new.vue?macro=true";
import { default as _91id_93vqkfXyhqR8Meta } from "/app/pages/dashboard/chat/[id].vue?macro=true";
import { default as indexFhDj7PwDByMeta } from "/app/pages/dashboard/chat/index.vue?macro=true";
import { default as _91id_93L3OtqKFzcyMeta } from "/app/pages/dashboard/coupons/[id].vue?macro=true";
import { default as index3oogZWetvmMeta } from "/app/pages/dashboard/coupons/index.vue?macro=true";
import { default as _91id_93Cr099wbz4NMeta } from "/app/pages/dashboard/customers/[id].vue?macro=true";
import { default as indexLUaCPnCuy2Meta } from "/app/pages/dashboard/customers/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as _91id_93AeFAPIHNEcMeta } from "/app/pages/dashboard/line/cart-drop/[id].vue?macro=true";
import { default as indexYIUIqin9FBMeta } from "/app/pages/dashboard/line/cart-drop/index.vue?macro=true";
import { default as _91id_93oaROlZRSFpMeta } from "/app/pages/dashboard/line/promotion/[id].vue?macro=true";
import { default as indexB8UqQqV7CyMeta } from "/app/pages/dashboard/line/promotion/index.vue?macro=true";
import { default as _91id_93pXOvuX7OZHMeta } from "/app/pages/dashboard/line/schedule/[id].vue?macro=true";
import { default as index2Sztjmm2bUMeta } from "/app/pages/dashboard/line/schedule/index.vue?macro=true";
import { default as _91id_93WS9oTT88oHMeta } from "/app/pages/dashboard/line/senarios/[id].vue?macro=true";
import { default as indexLfQiE8tf5bMeta } from "/app/pages/dashboard/line/senarios/index.vue?macro=true";
import { default as indexGzzgUEclteMeta } from "/app/pages/dashboard/line/tag/index.vue?macro=true";
import { default as indexCe4wnKEogUMeta } from "/app/pages/dashboard/line/templates/index.vue?macro=true";
import { default as _91id_93OIKtj8rYmKMeta } from "/app/pages/dashboard/orders/[id].vue?macro=true";
import { default as index2tHatxspqcMeta } from "/app/pages/dashboard/orders/index.vue?macro=true";
import { default as _91id_93PW20Fsr4DdMeta } from "/app/pages/dashboard/products/[id].vue?macro=true";
import { default as indext28BEwPvRxMeta } from "/app/pages/dashboard/products/index.vue?macro=true";
import { default as _91id_93J93wfIaucMMeta } from "/app/pages/dashboard/reserves/[id].vue?macro=true";
import { default as indexc9KK27rnqVMeta } from "/app/pages/dashboard/reserves/index.vue?macro=true";
import { default as indexcHcmKLACm3Meta } from "/app/pages/dashboard/sales/index.vue?macro=true";
import { default as transactionsrqUPes4pkWMeta } from "/app/pages/dashboard/sales/transactions.vue?macro=true";
import { default as withdraw5iXytWlSqPMeta } from "/app/pages/dashboard/sales/withdraw.vue?macro=true";
import { default as companywY6MeC8vcdMeta } from "/app/pages/dashboard/settings/company.vue?macro=true";
import { default as connectY9YGWfJLSuMeta } from "/app/pages/dashboard/settings/connect.vue?macro=true";
import { default as eventsU1ce79nW7rMeta } from "/app/pages/dashboard/settings/events.vue?macro=true";
import { default as indexMz7ffZfliDMeta } from "/app/pages/dashboard/settings/index.vue?macro=true";
import { default as membersas6OZm5lH8Meta } from "/app/pages/dashboard/settings/members.vue?macro=true";
import { default as notificationsCJBk3BYClnMeta } from "/app/pages/dashboard/settings/notifications.vue?macro=true";
import { default as shipping_45methods85yliMkDHXMeta } from "/app/pages/dashboard/settings/shipping-methods.vue?macro=true";
import { default as storel3ef9P0w64Meta } from "/app/pages/dashboard/settings/store.vue?macro=true";
import { default as stripe3n26LvNO1sMeta } from "/app/pages/dashboard/settings/stripe.vue?macro=true";
import { default as setupMI1iC4Gl21Meta } from "/app/pages/dashboard/setup.vue?macro=true";
import { default as _91id_93pvBs285615Meta } from "/app/pages/dashboard/tours/[id].vue?macro=true";
import { default as index1yZTM42azcMeta } from "/app/pages/dashboard/tours/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "admin__index",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "boarding__index",
    path: "/boarding",
    meta: indexSYNKnKeWmMMeta || {},
    component: () => import("/app/pages/boarding/index.vue").then(m => m.default || m)
  },
  {
    name: "boarding__invitation",
    path: "/boarding/invitation",
    meta: invitationa0U8Sz6KmFMeta || {},
    component: () => import("/app/pages/boarding/invitation.vue").then(m => m.default || m)
  },
  {
    name: "boarding__new",
    path: "/boarding/new",
    meta: new33EJdDPBaQMeta || {},
    component: () => import("/app/pages/boarding/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__chat__id",
    path: "/dashboard/chat/:id()",
    meta: _91id_93vqkfXyhqR8Meta || {},
    component: () => import("/app/pages/dashboard/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__chat__index",
    path: "/dashboard/chat",
    meta: indexFhDj7PwDByMeta || {},
    component: () => import("/app/pages/dashboard/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__coupons__id",
    path: "/dashboard/coupons/:id()",
    meta: _91id_93L3OtqKFzcyMeta || {},
    component: () => import("/app/pages/dashboard/coupons/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__coupons__index",
    path: "/dashboard/coupons",
    meta: index3oogZWetvmMeta || {},
    component: () => import("/app/pages/dashboard/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__customers__id",
    path: "/dashboard/customers/:id()",
    meta: _91id_93Cr099wbz4NMeta || {},
    component: () => import("/app/pages/dashboard/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__customers__index",
    path: "/dashboard/customers",
    meta: indexLUaCPnCuy2Meta || {},
    component: () => import("/app/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__index",
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__cart-drop__id",
    path: "/dashboard/line/cart-drop/:id()",
    meta: _91id_93AeFAPIHNEcMeta || {},
    component: () => import("/app/pages/dashboard/line/cart-drop/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__cart-drop__index",
    path: "/dashboard/line/cart-drop",
    meta: indexYIUIqin9FBMeta || {},
    component: () => import("/app/pages/dashboard/line/cart-drop/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__promotion__id",
    path: "/dashboard/line/promotion/:id()",
    meta: _91id_93oaROlZRSFpMeta || {},
    component: () => import("/app/pages/dashboard/line/promotion/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__promotion__index",
    path: "/dashboard/line/promotion",
    meta: indexB8UqQqV7CyMeta || {},
    component: () => import("/app/pages/dashboard/line/promotion/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__schedule__id",
    path: "/dashboard/line/schedule/:id()",
    meta: _91id_93pXOvuX7OZHMeta || {},
    component: () => import("/app/pages/dashboard/line/schedule/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__schedule__index",
    path: "/dashboard/line/schedule",
    meta: index2Sztjmm2bUMeta || {},
    component: () => import("/app/pages/dashboard/line/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__scenario__id",
    path: "/dashboard/line/senarios/:id()",
    meta: _91id_93WS9oTT88oHMeta || {},
    component: () => import("/app/pages/dashboard/line/senarios/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__scenario__index",
    path: "/dashboard/line/senarios",
    meta: indexLfQiE8tf5bMeta || {},
    component: () => import("/app/pages/dashboard/line/senarios/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__line_tags__index",
    path: "/dashboard/line/tag",
    meta: indexGzzgUEclteMeta || {},
    component: () => import("/app/pages/dashboard/line/tag/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__line_templates__index",
    path: "/dashboard/line/templates",
    meta: indexCe4wnKEogUMeta || {},
    component: () => import("/app/pages/dashboard/line/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__orders__id",
    path: "/dashboard/orders/:id()",
    meta: _91id_93OIKtj8rYmKMeta || {},
    component: () => import("/app/pages/dashboard/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__orders__index",
    path: "/dashboard/orders",
    meta: index2tHatxspqcMeta || {},
    component: () => import("/app/pages/dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__products__id",
    path: "/dashboard/products/:id()",
    meta: _91id_93PW20Fsr4DdMeta || {},
    component: () => import("/app/pages/dashboard/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__products__index",
    path: "/dashboard/products",
    meta: indext28BEwPvRxMeta || {},
    component: () => import("/app/pages/dashboard/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__reserves__id",
    path: "/dashboard/reserves/:id()",
    meta: _91id_93J93wfIaucMMeta || {},
    component: () => import("/app/pages/dashboard/reserves/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__reserves__index",
    path: "/dashboard/reserves",
    meta: indexc9KK27rnqVMeta || {},
    component: () => import("/app/pages/dashboard/reserves/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__sales__index",
    path: "/dashboard/sales",
    meta: indexcHcmKLACm3Meta || {},
    component: () => import("/app/pages/dashboard/sales/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__sales__transactions",
    path: "/dashboard/sales/transactions",
    meta: transactionsrqUPes4pkWMeta || {},
    component: () => import("/app/pages/dashboard/sales/transactions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__sales__withdraw",
    path: "/dashboard/sales/withdraw",
    meta: withdraw5iXytWlSqPMeta || {},
    component: () => import("/app/pages/dashboard/sales/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__company",
    path: "/dashboard/settings/company",
    meta: companywY6MeC8vcdMeta || {},
    component: () => import("/app/pages/dashboard/settings/company.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__connect",
    path: "/dashboard/settings/connect",
    meta: connectY9YGWfJLSuMeta || {},
    component: () => import("/app/pages/dashboard/settings/connect.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__events",
    path: "/dashboard/settings/events",
    meta: eventsU1ce79nW7rMeta || {},
    component: () => import("/app/pages/dashboard/settings/events.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__index",
    path: "/dashboard/settings",
    meta: indexMz7ffZfliDMeta || {},
    component: () => import("/app/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__members",
    path: "/dashboard/settings/members",
    meta: membersas6OZm5lH8Meta || {},
    component: () => import("/app/pages/dashboard/settings/members.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__notifications",
    path: "/dashboard/settings/notifications",
    meta: notificationsCJBk3BYClnMeta || {},
    component: () => import("/app/pages/dashboard/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__shipping-methods",
    path: "/dashboard/settings/shipping-methods",
    meta: shipping_45methods85yliMkDHXMeta || {},
    component: () => import("/app/pages/dashboard/settings/shipping-methods.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__store",
    path: "/dashboard/settings/store",
    meta: storel3ef9P0w64Meta || {},
    component: () => import("/app/pages/dashboard/settings/store.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__settings__stripe",
    path: "/dashboard/settings/stripe",
    meta: stripe3n26LvNO1sMeta || {},
    component: () => import("/app/pages/dashboard/settings/stripe.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__setup",
    path: "/dashboard/setup",
    meta: setupMI1iC4Gl21Meta || {},
    component: () => import("/app/pages/dashboard/setup.vue").then(m => m.default || m)
  },
  {
    name: "dashboard__tours__id",
    path: "/dashboard/tours/:id()",
    meta: _91id_93pvBs285615Meta || {},
    component: () => import("/app/pages/dashboard/tours/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard__tours__index",
    path: "/dashboard/tours",
    meta: index1yZTM42azcMeta || {},
    component: () => import("/app/pages/dashboard/tours/index.vue").then(m => m.default || m)
  },
  {
    name: "root__index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]