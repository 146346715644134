export default defineNuxtPlugin({
    enforce: 'pre', // clients will be ready to use by other plugins, Pinia stores etc.
    setup() {
        const { public: { openFetch: clients } } = useRuntimeConfig()
        const runtimeConfig = useRuntimeConfig()
        const router = useRouter()
        return {
            provide: Object.fromEntries(Object.entries(clients).map(([name, options]) => [
                name,
                createOpenFetch(localOptions => ({
                    ...options,
                    ...localOptions,
                    baseURL: `${import.meta.server ? runtimeConfig.BACKEND_ORIGIN : runtimeConfig.public.BACKEND_ORIGIN}`,
                    onRequest(ctx) {
                        const authStore = useAuthStore()
                        const storePinia = useStorePinia()
                        if (authStore.isLoggedIn) {
                            ctx.options.headers = {
                                ...ctx.options.headers,
                                Authorization: `Bearer ${authStore.token}`,
                            }
                        }
                        if (storePinia.selectedId) {
                            ctx.options.headers = {
                                ...ctx.options.headers,
                                'X-Selected-Id': storePinia.selectedId,
                            }
                        }
                        return localOptions?.onRequest?.(ctx)
                    },
                    onResponseError: (error) => {
                        const authStore = useAuthStore()
                        console.log('ResponseError', error.response.status)
                        if (error.response.status === 401) {
                            authStore._clear()
                            if (!import.meta.server) {
                                window.location.href = runtimeConfig.public.AUTH_ORIGIN + runtimeConfig.public.LOGIN_PATH + '?redirect=' + urlSafeBtoa(router.currentRoute.value.fullPath)
                            }
                        }
                        return localOptions?.onResponseError?.(error)
                    },
                })),
            ])),
        }
    },
})
