import { createUseOpenFetch } from '#imports'
import type { paths as BackendPaths } from '#open-fetch-schemas/backend'

export type OpenFetchClientName = 'backend'

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useBackend = createUseOpenFetch<BackendPaths>('backend')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyBackend = createUseOpenFetch<BackendPaths>('backend', true)
