export default defineAppConfig({
    ui: {
        primary: 'indigo',
        formGroup: {
            error: 'text-xs',
            help: 'text-xs whitespace-pre-wrap text-gray-500',
            label: {
                wrapper: 'flex content-center items-center justify-between',
                base: 'block font-medium text-gray-700 text-sm',
                required: 'after:content-[\'*\'] after:ms-0.5 after:text-red-500',
            },
        },
        card: { body: { base: 'flex-grow' } },
        tooltip: {
            base: 'whitespace-pre-wrap h-auto',
        },
        container: {
            constrained: 'max-w-6xl',
        },
        table: {
            default: {
                loadingState: {
                    icon: 'i-heroicons-arrow-path-20-solid',
                    label: '読み込み中...',
                },
                emptyState: {
                    icon: 'i-heroicons-circle-stack-20-solid',
                    label: 'データがありません',
                },
            },
        },
    },
    auth: {
        redirect: {
            login: '/',
            logout: '/',
        },
        routes: {
            login: '/auth/login',
            logout: '/auth/logout',
        },
    },
})
