import { differenceInCalendarDays } from 'date-fns'

export const calcAge = (birthday: Date) => {
    const age = differenceInCalendarDays(new Date(), birthday) / 365
    return Math.round(age)
}

export const formatShortDate = (date: Date, includeTime = true) => {
    /*

    同じ年なら省略する
    分がないなら省略する

    */
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    const now = new Date()
    const nowYear = now.getFullYear()

    let result = ''
    if (year !== nowYear) {
        result += `${year}年`
    }
    result += `${month}月${day}日 `
    if (includeTime) {
        result += `${hour}時`
        if (minute !== 0) {
            result += `${minute}分`
        }
    }
    return result
}

export const getLink = (app: string, path: string) => {
    const runtimeConfig = useRuntimeConfig()
    if (app === 'auth') {
        return `${runtimeConfig.public.AUTH_ORIGIN}${path}`
    }
    return `${path}`
}

export const urlSafeBtoa = (input: string) => {
    return btoa(input).replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
}

export const urlSafeAtob = (input: string) => {
    return atob(input.replace(/-/g, '+').replace(/_/g, '/'))
}
