import type { NuxtApp } from '#app'
import { defineStore } from 'pinia'
import type { components } from '#open-fetch-schemas/backend'

export const useStoreListPinia = defineStore('storeListPinia', {
    state: () => ({
        _storeList: [] as components['schemas']['Store'][],
    }),
    actions: {
        async fetchStoreList() {
            const authStore = useAuthStore()
            if (!authStore.isLoggedIn) {
                return
            }
            const { data } = await useBackend('/v1/stores/')
            this._storeList = data.value?.results || []
        },
        async init() {
            if (this._storeList.length > 0) {
                return
            }
            await this.fetchStoreList()
        },
        clear() {
            this._storeList = []
        },
    },
    getters: {
        storeList: (state) => {
            return state._storeList
        },
    },
    persist: false,
})

export const useStorePinia = defineStore('storePinia', {
    state: () => ({
        _selectedId: null as string | null,
    }),
    actions: {
        async fetchStoreList() {
            const storeList = useStoreListPinia()
            await storeList.fetchStoreList()
        },
        switchStore(id: string) {
            this._selectedId = id
        },
        async init() {
            const storeList = useStoreListPinia()
            await storeList.init()
            if (storeList.storeList.length > 0 && !this._selectedId) {
                this._selectedId = storeList.storeList[0].id
            }
            if (this._selectedId && !storeList.storeList.find(store => store.id === this._selectedId)) {
                this._selectedId = storeList.storeList[0].id
            }
            if (storeList.storeList.length === 0 && !import.meta.server) {
                const router = useRouter()
                router.push({ name: 'boarding__index' })
            }
        },
    },
    getters: {
        selectedStore: (state) => {
            const storeList = useStoreListPinia()
            return storeList.storeList?.find(store => store.id === state._selectedId) || null
        },
        storeList: (state) => {
            const storeList = useStoreListPinia()
            return storeList.storeList || []
        },
        selectedId: (state) => {
            return state._selectedId
        },
    },
    persist: true,
})

export const useTenantPinia = defineStore('tenantPinia', {
    state: () => ({
        _tenants: {} as {
            [slug: string]: components['schemas']['StorePublic']
        },
    }),
    actions: {
        pushTenant(tenant: any) {
            this._tenants[tenant.slug] = tenant
        },
        async fetchTenant(slug: string, force = false) {
            if (force) {
                delete this._tenants[slug]
            }
            if (this._tenants[slug]) {
                console.log('Tenant already exists')
                return
            }
            const { data, status } = await useBackend('/v1/public/stores/{slug}/', {
                path: { slug },
                getCachedData: (key: string, nuxtApp: NuxtApp) => {
                    return undefined
                },
            })
            if (data.value && status.value === 'success') {
                this.pushTenant(data.value)
            } else {
                throw createError({
                    statusCode: 404,
                    statusMessage: 'Page Not Found',
                    fatal: true,
                })
            }
        },
        clear() {
            this._tenants = {}
        },
    },
    getters: {
        tenant: (state) => {
            const subdomain = useSubdomain()
            if (subdomain === 'root') {
                return null
            }
            return state._tenants[subdomain]
        },
        tenants: (state) => {
            return state._tenants
        },
    },
})
