import { defineStore } from 'pinia'
export const useAuthStore = defineStore('authMogutte', {
    state: () => {
        return {
            _user: null as any | null,
            token: null as string | null,
            expires: null as string | null,
        }
    },
    getters: {
        isLoggedIn: (state) => {
            return !!state.token && !!state.expires && new Date(state.expires) > new Date()
        },
        user: (state) => {
            return state._user
        },
    },
    actions: {
        setUser(user: any) {
            this._user = user
        },
        setToken(token: string) {
            this.token = token
        },
        setExpires(expires: string) {
            this.expires = expires
        },
        _clear() {
            this._user = null
            this.token = null
            this.expires = null
        },
    },
    persist: true,
})

export const useAuth = () => {
    const login = async (body: object) => {
        const { setToken, setExpires } = useAuthStore()
        const { data: response, status, error } = await useBackend('/v1/auth/login/', {
            method: 'post',
            body: body as any,
            watch: false,
        })
        if (status.value === 'success') {
            setToken(String(response.value?.token))
            setExpires(String(response.value?.expires))
            await fetchUser()
        }
        return {
            status,
            error,
            data: response,
        }
    }

    const fetchUser = async () => {
        const { setUser } = useAuthStore()
        const { data: response } = await useBackend('/v1/self/', {
            method: 'get',
        })
        setUser(response.value)
    }

    const logout = async () => {
        const { _clear } = useAuthStore()
        const storeList = useStoreListPinia()
        const tenant = useTenantPinia()
        await useBackend('/v1/auth/logout/', {
            method: 'post',
        })
        _clear()
        storeList.clear()
        tenant.clear()
        window.location.href = useRuntimeConfig().public.APP_ORIGIN
    }

    return {
        login,
        fetchUser,
        logout,
    }
}
