export const useSubdomain = () => {
    const { ssrContext } = useNuxtApp()

    const subdomain = (import.meta.server && ssrContext && ssrContext.event.node.req)
        ? ssrContext.event.node.req.headers.host?.split('.')[0]
        : (
                import.meta.client && window.location.hostname
                    ? window.location.hostname.split('.')[0]
                    : null
            )
    if (!subdomain || subdomain === 'www' || subdomain === 'localhost:3000' || subdomain === 'localhost' || subdomain === 'mogutte') {
        return 'root'
    } else {
        return subdomain
    }
}
