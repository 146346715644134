import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-app": () => import("/app/middleware/auth-app.ts"),
  "auth-auth": () => import("/app/middleware/auth-auth.ts"),
  "auth-liff": () => import("/app/middleware/auth-liff.ts"),
  banned: () => import("/app/middleware/banned.ts"),
  tenant: () => import("/app/middleware/tenant.ts")
}